import React, {useEffect} from "react";
import { useFormContext } from "react-hook-form";

const FieldInput = ({name, label, value, onChange, required}) => {
    const { register, setValue } = useFormContext();

    useEffect(() => {
        value && setValue(name, value)
    }, [])

    const change = (event) => {
        setValue(name, event.target.value);
        onChange(name, event.target.value);
    };

    return (
        <input type="text" className="uk-input" name={name}
               value={value} onChange={change} placeholder={label}
               {...register(name, {required})}
               
        />
    );
}
export default FieldInput;
