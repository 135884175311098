import Handlebars from 'handlebars/dist/cjs/handlebars'

const tpl = `<table style="border-collapse:collapse;border-spacing:0;width:530px;background:#fff;">
<tr>
<td style="padding:15px 0 15px 15px;vertical-align:top;">
<p style="color:#656567;font-family:Roboto,Arial,sans-serif;font-size:14px;line-height:16px;margin:0 auto;padding:0;text-align:left;">Kind Regards,</p>
</td>
</tr>
<tr>
<td style="padding:0;vertical-align:top;">
<table height="173" style="background:url('https://sg.gy/i/x.jpeg') no-repeat left top;border-collapse:collapse;border-spacing:0;width:530px;">
<tr>
<td style="padding:12px 0 0 15px;vertical-align:top;">
<table style="border-collapse:collapse;border-spacing:0;width:100%;">
<tr>
<td align="center" style="padding:0;vertical-align:top;width:120px;">
<table style="border-collapse:collapse;border-spacing:0;width:100%;">
<tr><td style="font-size:1px;line-height:7px;padding:0;vertical-align:top;">&nbsp;</td></tr></table>
<img src="{{#if card.avatar}}{{card.avatar}}{{else}}//sg.gy/i/p.png{{/if}}" width="120" style="display:block;border:0;height:auto;">
<table style="border-collapse:collapse;border-spacing:0;width:auto;">
<tr><td style="padding:0;vertical-align:top;">
<table style="border-collapse:collapse;border-spacing:0;width:100%;">
<tr><th style="padding:12px 4px 0">
<a href="{{#if card.fb}}{{card.fb}}{{else}}https://www.facebook.com/emortgagecapital/{{/if}}" style="color:#2199e8;">
<img src="//sg.gy/i/f.png" width="20" style="display:block;margin:0 auto;border:0;height:auto;"></a>
</th><th style="padding:12px 4px 0">
<a href="{{#if card.inst}}{{card.inst}}{{else}}https://www.instagram.com/emortgagecapital/{{/if}}" style="color:#2199e8;">
<img src="//sg.gy/i/i.png" width="20" style="display:block;margin:0 auto;border:0;height:auto;"></a></th>
<th style="padding:12px 4px 0">
<a href="{{#if card.in}}{{card.in}}{{else}}https://www.linkedin.com/company/e-mortgage-capital{{/if}}" style="color:#2199e8;">
<img src="//sg.gy/i/l.png" width="20" style="display:block;margin:0 auto;border:0;height:auto;"></a>
</th></tr></table></td></tr></table>
</td><td style="padding:0;vertical-align:top;">
<p style="color:#656567;font-family:Roboto,Arial,sans-serif;font-size:16px;line-height:20px;margin:0 auto;padding:0;text-align:left;">
<strong>{{#if card.name}}{{card.name}}{{else}}Test Ra{{/if}}</strong> | E Mortgage Capital, Inc.</p>
<table style="border-collapse:collapse;border-spacing:0;width:100%;">
<tr><td style="font-size:1px;line-height:4px;padding:0;vertical-align:top;">&nbsp;</td></tr></table>
<table style="border-collapse:collapse;border-spacing:0;width:100%;"><tr>
<td style="padding:0 0 0 15px;vertical-align:top;">
<p style="color:#656567;font-family:Roboto,Arial,sans-serif;font-size:13px;line-height:17px;margin:0 auto;padding:0;text-align:left;">{{#if card.position}}{{card.position}}{{else}}Brand manager{{/if}}</p>
<p style="color:#656567;font-family:Roboto,Arial,sans-serif;font-size:10px;line-height:14px;margin:0 auto;padding:0;text-align:left;">{{# if card.nmls }}NMLS#{{card.nmls}}{{/if}}{{# if card.nmls_personal }}&nbsp;NMLS#{{card.nmls_personal}}{{/if}}{{# if card.dre }}&nbsp;DRE#{{card.dre}}{{/if}}</p>
<table style="border-collapse:collapse;border-spacing:0;width:100%;"><tr>
<td style="font-size:1px;line-height:8px;padding:0;vertical-align:top;">&nbsp;</td>
</tr></table>
<table style="border-collapse:collapse;border-spacing:0;width:auto;"><tr><td style="padding:0">
<table style="border-collapse:collapse;border-spacing:0;width:auto;"><tr>
<td style="padding:0"> <table style="border-collapse:collapse;border-spacing:0;width:auto;"><tr>
<td style="padding:0 7px 3px 0;vertical-align:middle;width:16px;">
<img src="https://sg.gy/i/o.png" width="16" style="display:block;border:0;height:auto;"></td>
<td style="padding:0 0 3px;vertical-align:middle;">
<p style="color:#656567;font-family:Roboto,Arial,sans-serif;font-size:11px;line-height:16px;margin:0 auto;padding:0;text-align:left;">
<a href="tel:{{#if card.phone}}{{card.phone}}{{else}}855 569 3700{{/if}}" style="color:#656567;">{{#if card.phone}}{{card.phone}}{{else}}855 569 3700{{/if}}</a></p>
</td></tr></table>
</td>
<td style="padding:0;width:10px"></td>
{{#if card.faxPhone}}
<td style="padding:0">
<table style="border-collapse:collapse;border-spacing:0;width:auto;">
    <tr><td style="padding:0 7px 3px 0;vertical-align:middle;width:16px;">
    <img src="https://sg.gy/i/t.png" width="16" style="display:block;border:0;height:auto;">
    </td>
    <td style="padding:0 0 3px;vertical-align:middle;">
    <p style="color:#656567;font-family: Roboto,Arial,sans-serif;font-size:11px;line-height:16px;margin:0 auto;padding:0;text-align:left;">
    <a href="tel:{{card.faxPhone}}" style="color:#656567;">{{card.faxPhone}}</a>
    </p>
    </td>
    </tr>
</table>
</td>
{{/if}}
</tr></table></td></tr>
{{# if card.homePhone}}
<tr>
<td style="padding:0">
<table style="border-collapse:collapse;border-spacing:0;width:100%;;height:19px"><tr>
<td style="padding:0 7px 3px 0;vertical-align:middle;width:16px;">
<img src="https://sg.gy/i/u.png" width="16" style="display:block;border:0;height:auto;">
</td><td style="padding:0 0 3px;vertical-align:middle;">
<p style="color:#656567;font-family: Roboto,Arial,sans-serif;font-size:11px;line-height:16px;margin:0 auto;padding:0;text-align:left;">
<a href="tel:{{card.homePhone}}" style="color:#656567;">{{card.homePhone}}</a>
</p>
</td>
</tr>
</table>
</td>
</tr>
{{/if}}
<tr>
<td style="padding:0">
<table style="border-collapse:collapse;border-spacing:0;width:100%;">
<tr>
<td style="padding:0 7px 3px 0;vertical-align:middle;width:16px;">
<img src="https://sg.gy/i/e.png" width="16" style="display:block;border:0;height:auto;">
</td>
<td style="padding:0 0 3px;vertical-align:middle;">
<p style="color:#656567;font-family:Roboto,Arial,sans-serif;font-size:11px;line-height:16px;margin:0 auto;padding:0;text-align:left;">
<a href="mailto:{{card.email}}" style="color:#656567;">{{#if card.email}}{{card.email}}{{else}}youremail@emortgagecapital.com{{/if}}</a>
</p>
</td></tr></table>
</td></tr><tr>
<td style="padding:0">
<table style="border-collapse:collapse;border-spacing:0;width:100%;"><tr>
<td style="padding:0 7px 3px 0;vertical-align:middle;width:16px;">
<img src="https://sg.gy/i/m.png" width="16" style="display:block;border:0;height:auto;">
</td><td style="padding:0 0 3px;vertical-align:middle;">
<p style="color:#656567;font-family:Roboto,Arial,sans-serif;font-size:11px;line-height:16px;margin:0 auto;padding:0;text-align:left;">
<a href="https://maps.google.com/?q={{#if card.address}}{{card.address}}{{else}}939 E Santa Ana Blvd, Santa Ana CA 92701{{/if}}" style="color:#656567;">{{#if card.address}}{{card.address}}{{else}}939 E Santa Ana Blvd, Santa Ana CA 92701{{/if}}</a>
</p></td></tr></table>
</td></tr></table>
<p style="color:#656567;font-family: Roboto,Arial,sans-serif;font-size:16px;line-height:18px;margin:0 auto;padding:0;text-align:left;">
<a href="{{#if card.apply_now}}{{card.apply_now}}{{else}}https://emchq.floify.com/{{/if}}" style="color:#1f3864;font-weight:500;text-decoration:underline;">Apply Now</a><span style="color:#52afe6"> | </span>
<a href="{{#if card.document_upload}}{{card.document_upload}}{{else}}https://emchq.floify.com/{{/if}}" style="color:#1f3864;font-weight:500;text-decoration:underline;">Document Upload</a><span style="color:#52afe6"> | </span>
<a href="{{#if card.reviews}}{{card.reviews}}{{else}}https://emortgagecapital.com/reviews/{{/if}}" style="color:#1f3864;font-weight:500;text-decoration:underline;">Reviews</a></p>
{{#if card.schedule_appt}}
<a href="{{card.schedule_appt}}" style="color:#1f3864;font-weight:500;text-decoration:underline;">Schedule Appt</a></p>
{{/if}}
</td></tr></table></td></tr></table> </td></tr></table></td></tr><tr><td style="padding:10px 0 5px 15px;vertical-align:top;"><a href="https://emortgagecapital.com/"><img src="//sg.gy/i/b.png" width="515" style="display:block;border:0;height:auto;"></a></td></tr></table>`;

export const getTpl = (card) => {
    const template = Handlebars.compile(tpl);
    return template({'card':card});
}
