
const FieldSocial = ({type, label, value, onChange}) => {
    const change = (event) => {
        onChange(type, event.target.value);
    };

    return (
        <input type="text" className="uk-input" value={value} onChange={change} placeholder={label}/>
    );
}
export default FieldSocial;
