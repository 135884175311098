import React, {useState} from 'react'
import Avatar from 'react-avatar-edit'
import axios from 'axios'
import {useFormContext} from "react-hook-form"

const FieldAvatar = ({onSelect}) => {
    const [src, setSrc] = useState(null);
    const [preview, setPreview] = useState(null);
    const { register, setValue } = useFormContext();

    const getFilename = (dataUrl) => {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        return Math.round(+new Date()/1000) + '.' + mime.split('/').pop();
    }

    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n) {
            u8arr[n - 1] = bstr.charCodeAt(n - 1)
            n -= 1
        }
        return new File([u8arr], filename, { type: mime })
    }

    const onClose = ()=>{
        onSelect(null);
        setValue('avatar', null);
    }

    const onCrop = (preview)=>{
        setPreview(preview);
    }

    const onBeforeFileLoad=(elem)=>{
        if(elem.target.files[0].size > 27168000){
            alert("File is too big!");
            elem.target.value = "";
        };
    }

    const onApply = async (e) => {
        e.preventDefault();
        const name = getFilename(preview)
        const file = dataURLtoFile(preview, name)
        const form = new FormData()
        form.append('image', preview)
        form.append('name', 'test')

        try{
            const {data} = await axios.post(
                'https://esig.emortgagecapital.com/image.php',
                form,
                {headers: { 'Content-Type': 'multipart/form-data' }})
            setValue('avatar', data.link);
            onSelect(data.link);
        }catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <Avatar
                width={120}
                height={120}
                exportSize={140}
                exportAsSquare={false}
                exportQuality={1.0}
                onCrop={onCrop}
                onClose={onClose}
                label={"Choice"}
                onBeforeFileLoad={onBeforeFileLoad}
                ref={register('avatar', { required: true })}
                src={src}
            />
            {preview && <div className="uk-margin"><button className="uk-button" onClick={onApply}>Apply</button></div>}
        </>
    );
}
export default FieldAvatar;
