import {useRef, useState} from "react";
import ReactDOM from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';
import copy from 'copy-html-to-clipboard';
import { useForm, FormProvider } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import FieldAvatar from "./field/FieldAvatar";
import FieldSocial from "./field/FieldSocial";
import FieldInput from "./field/FieldInput";
import {getTpl} from "./tpl";

const init = {
    avatar:null,
    name:'',
    position: '',
    nmls: '1416824',
    nmls_person: '',
    dre: '',
    phone: '',
    homePhone: '',
    faxPhone: '',
    email: '',
    address: '',
    in: '',
    fb: '',
    ytb: '',
    inst: '',
    apply_now: '',
    document_upload: '',
    reviews: '',
    schedule_appt: ''
}

const load = () => {
    const key = window.localStorage.getItem('last_date')
    if (key){
        let days4 = new Date(key)
        days4.setDate(days4.getDate() + 4);
        if (days4.getTime() < new Date().getTime()) return null
        const data = window.localStorage.getItem('email_data')
        return JSON.parse(data)
    }
    return null
}

const store = (data) => {
    window.localStorage.setItem('last_date', new Date().toString())
    window.localStorage.setItem('email_data', JSON.stringify(data))
}

function App() {
  const textAreaRef = useRef();
  const textRef = useRef();
  const methods = useForm();
  const {errors} = methods;
  const data = load()
  const [sign, setSign] = useState('');
  const [card, setCard] = useState(data || init);

  const onSelectAvatar = (data) => {
      setCard(prevState => ({
          ...prevState,
          avatar : data
      }));
      setSign(ReactDOM.findDOMNode(textAreaRef.current).innerHTML);
  }

  const onCopy = () => {
      const tpl = getTpl(card)
      console.log('length', tpl.length)
      console.log(tpl)
      copy(tpl, {
          asHtml: true,
      });
      toast('Email sign copied');
  }

  const onChange=(name, value)=> {
      setCard(prevState => ({...prevState, [name] : value}));
      setSign(ReactDOM.findDOMNode(textAreaRef.current).innerHTML);
      store(card)
  }

  const clear = () => {
      window.localStorage.removeItem('last_date')
      window.localStorage.removeItem('email_data')
      setCard(init)
  }

  const onChangeSocial=(name, value)=> {
      setCard(prevState => ({...prevState, [name] : value}));
      setSign(ReactDOM.findDOMNode(textAreaRef.current).innerHTML);
  }

    const onGenerate = () => {
        textRef.current.value = textAreaRef.current.innerHTML;
    }

  return (
    <div className="App uk-grid uk-grid-medium">
          <div className="uk-width-1-2 uk-padding-large">
              <FormProvider {...methods} >
                  <form onSubmit={methods.handleSubmit(onCopy)}>
              <div>
                <FieldAvatar onSelect={onSelectAvatar} />
                  {errors?.avatar && <span>Please fill in the required fields.</span>}
              </div>
              <div className="uk-margin">
                  <FieldInput name="name" label="Insert Name" required value={card.name} onChange={onChange} />
                  {errors?.name && <span>Please fill in the required fields.</span>}
              </div>
              <div className="uk-margin">
                  <FieldInput name="position" label="Insert job title/position" value={card.position} onChange={onChange} />
              </div>
              <div className="uk-margin">
                  <div className="uk-grid">
                      <div className="uk-width-1-3">
                          <FieldInput name="nmls" label="Insert NMLS if applicable" value={card.nmls} onChange={onChange} />
                      </div>
                      <div className="uk-width-1-3 uk-padding-small uk-padding-remove-vertical">
                          <FieldInput name="nmls_personal" label="Insert personal NMLS" value={card.nmls_personal} onChange={onChange} />
                      </div>
                      <div className="uk-width-1-3 uk-padding-remove">
                          <FieldInput name="dre" label="Insert DRE #" value={card.dre} onChange={onChange} />
                      </div>
                  </div>
              </div>
              <div className="uk-margin">
                  <FieldInput name="phone" label="Insert office #" value={card.phone} onChange={onChange} />
              </div>
              <div className="uk-margin">
                  <FieldInput name="homePhone" label="Insert cell #" value={card.homePhone} onChange={onChange} />
              </div>
              <div className="uk-margin">
                  <FieldInput name="faxPhone" label="Insert fax #" value={card.faxPhone} onChange={onChange} />
              </div>
              <div className="uk-margin">
                  <FieldInput name="address" label="Insert office address" value={card.address} onChange={onChange} />
              </div>
              <div className="uk-margin">
                  <FieldInput name="email" label="Insert email address" value={card.email} required onChange={onChange} />
                  {errors?.email && <span>Please fill in the required fields.</span>}
              </div>
              <div className="uk-margin">
                  <FieldInput name="apply_now" label="Insert apply now link funnel" value={card.apply_now} onChange={onChange} />
              </div>
              <div className="uk-margin">
                  <FieldInput name="document_upload" label="Insert document upload link if applicable" value={card.document_upload} onChange={onChange} />
              </div>
              <div className="uk-margin">
                  <FieldInput name="reviews" label="Insert reviews link if applicable" value={card.reviews} onChange={onChange} />
              </div>
              <div className="uk-margin uk-grid">
                  <div className="uk-width-1-2">
                    <FieldSocial type="fb" label="Facebook" onChange={onChangeSocial} />
                  </div>
                  <div className="uk-width-1-2">
                    <FieldSocial type="inst" label="Instagram" onChange={onChangeSocial} />
                  </div>
              </div>
              <div className="uk-margin uk-grid">
                  <div className="uk-width-1-2">
                      <FieldSocial type="in" label="LinkedIn" onChange={onChangeSocial} />
                  </div>
                  <div className="uk-width-1-2">
                    <FieldSocial type="ytb" label="Youtube" onChange={onChangeSocial} />
                  </div>
              </div>
              <div className="uk-margin">
                  <FieldInput name="schedule_appt" label="Insert schedule appt" value={card.schedule_appt} onChange={onChange} />
              </div>
              <p className="uk-margin">
                <button className="uk-button uk-button-default uk-button-large">COPY SIGNATURE</button>
              </p>
              <p>
                  <a href="https://orangeskyinc.com/sign-tutorial/" target="_blank">Instructions</a>
              </p>
                  </form>
              </FormProvider>
          </div>
          <div className="uk-width-1-2 uk-padding-large">
              <div id="mail" ref={textAreaRef}>
                  <table className="sign" style={{borderCollapse: 'collapse', borderSpacing: 0, width: '530px', background: '#fff'}}>
                      <tbody><tr>
                          <td style={{padding: '15px 0 15px 15px', verticalAlign: 'top'}}>
                              <p style={{color: '#656567', fontFamily: 'Roboto,Arial,sans-serif', fontSize: '14px', lineHeight: '16px', margin: '0 auto', padding: 0, textAlign: 'left'}}>Kind Regards,</p>
                          </td>
                      </tr>
                      <tr>
                          <td style={{padding: 0, verticalAlign: 'top'}}>
                              <table background="https://sg.gy/i/x.jpeg" height={173} style={{backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', borderCollapse: 'collapse', borderSpacing: 0, width: '530px'}}>
                                  <tbody><tr>
                                      <td style={{padding: '12px 0 0 15px', verticalAlign: 'top'}}>
                                          <table style={{borderCollapse: 'collapse', borderSpacing: 0, width: '100%'}}>
                                              <tbody><tr>
                                                  <td align="center" style={{padding: 0, verticalAlign: 'top', width: '120px'}}>
                                                      <table style={{borderCollapse: 'collapse', borderSpacing: 0, width: '100%'}} role="presentation">
                                                          <tbody><tr>
                                                              <td style={{fontSize: '1px', lineHeight: '7px', padding: 0, verticalAlign: 'top'}}>&nbsp;</td>
                                                          </tr>
                                                          </tbody></table>
                                                      <img src={card.avatar !== null ? card.avatar : process.env.PUBLIC_URL+"/i/p.png"} width={120} alt="" style={{display: 'block', border: 0, height: 'auto'}} />
                                                      <table className="menu" style={{borderCollapse: 'collapse', borderSpacing: 0, width: 'auto'}}>
                                                          <tbody><tr>
                                                              <td style={{padding: 0, verticalAlign: 'top'}}>
                                                                  <table style={{borderCollapse: 'collapse', borderSpacing: 0, width: '100%'}}>
                                                                      <tbody><tr>
                                                                          <th className="menu-item" style={{padding: '12px 4px 0'}}>
                                                                              <a href={card.fb ? card.fb :"https://www.facebook.com/emortgagecapital/"} target="_blank" style={{color: '#2199e8', textDecoration: 'none'}}><img src="https://esig.emortgagecapital.com/full/img/fb.png" className="float-center" width={20} alt="fb" style={{display: 'block', margin: '0 auto', border: 0, height: 'auto'}} /></a>
                                                                          </th>
                                                                          <th className="menu-item" style={{padding: '12px 4px 0'}}>
                                                                              <a href={card.inst ? card.inst : "https://www.instagram.com/emortgagecapital/"} target="_blank" style={{color: '#2199e8', textDecoration: 'none'}}><img src="https://esig.emortgagecapital.com/full/img/inst.png" className="float-center" width={20} alt="inst" style={{display: 'block', margin: '0 auto', border: 0, height: 'auto'}} /></a>
                                                                          </th>
                                                                          <th className="menu-item" style={{padding: '12px 4px 0'}}>
                                                                              <a href={card.in ? card.in : "https://www.linkedin.com/company/e-mortgage-capital"} target="_blank" style={{color: '#2199e8', textDecoration: 'none'}}>
                                                                                  <img src="https://esig.emortgagecapital.com/full/img/in.png" className="float-center" width={20} alt="in" style={{display: 'block', margin: '0 auto', border: 0, height: 'auto'}} /></a>
                                                                          </th>
                                                                          <th className="menu-item" style={{padding: '12px 4px 0px'}}>
                                                                              <a href={card.ytb ? card.ytb : 'https://www.youtube.com/c/EMortgageCapital'} target="_blank" style={{color: '#2199e8', textDecoration: 'none'}}>
                                                                                  <img src="https://esig.emortgagecapital.com/full/img/ytb.png" className="float-center" width={20} alt="in" style={{display: 'block', margin: '0 auto', border: 0, height: 'auto'}} /></a>
                                                                          </th>
                                                                      </tr>
                                                                      </tbody></table>
                                                              </td>
                                                          </tr>
                                                          </tbody></table>
                                                  </td>
                                                  <td style={{padding: 0, verticalAlign: 'top'}}>
                                                      <p style={{color: '#656567', fontFamily: 'Roboto,Arial,sans-serif', fontSize: '16px', lineHeight: '20px', margin: '0 auto', padding: 0, textAlign: 'left'}}>
                                                          <strong>{!card.name && 'Test Ra'}
                                                              {card.name}</strong> | E Mortgage Capital, Inc.</p>
                                                      <table style={{borderCollapse: 'collapse', borderSpacing: 0, width: '100%'}} role="presentation">
                                                          <tbody><tr>
                                                              <td style={{fontSize: '1px', lineHeight: '4px', padding: 0, verticalAlign: 'top'}}>&nbsp;</td>
                                                          </tr>
                                                          </tbody></table>
                                                      <table style={{borderCollapse: 'collapse', borderSpacing: 0, width: '100%'}}>
                                                          <tbody><tr>
                                                              <td style={{padding: '0 0 0 15px', verticalAlign: 'top'}}>
                                                                  <p style={{color: '#656567', fontFamily: 'Roboto,Arial,sans-serif', fontSize: '13px', lineHeight: '17px', margin: '0 auto', padding: 0, textAlign: 'left'}}>{!card.position && 'Brand manager'}
                                                                      {card.position}</p>
                                                                  <p style={{color: '#656567', fontFamily: 'Roboto,Arial,sans-serif', fontSize: '10px', lineHeight: '14px', margin: '0 auto', padding: 0, textAlign: 'left'}}>
                                                                      {card.nmls &&
                                                                      <>
                                                                          NMLS#{card.nmls}
                                                                      </>}
                                                                      {card.nmls_personal &&
                                                                      <>
                                                                          &nbsp;NMLS#
                                                                          {card.nmls_personal}
                                                                      </>}
                                                                      {card.dre &&
                                                                      <>
                                                                          &nbsp;DRE#
                                                                          {card.dre}
                                                                      </>}
                                                                  </p>
                                                                  <table style={{borderCollapse: 'collapse', borderSpacing: 0, width: '100%'}} role="presentation">
                                                                      <tbody><tr>
                                                                          <td style={{fontSize: '1px', lineHeight: '8px', padding: 0, verticalAlign: 'top'}}>&nbsp;</td>
                                                                      </tr>
                                                                      </tbody></table>
                                                                  <table style={{borderCollapse: 'collapse', borderSpacing: 0, width: 'auto'}}>
                                                                      <tbody>
                                                                      <tr>
                                                                          <td style={{padding: 0}}>
                                                                              <table style={{borderCollapse: 'collapse', borderSpacing: 0, width: 'auto'}}>
                                                                                  <tbody><tr>
                                                                                      <td style={{padding: 0}}>
                                                                                          <table style={{borderCollapse: 'collapse', borderSpacing: 0, width: 'auto'}}>
                                                                                              <tbody><tr>
                                                                                                  <td style={{padding: '0 7px 3px 0', verticalAlign: 'middle', width: '16px'}}>
                                                                                                      <img src="https://esig.emortgagecapital.com/full/img/p1.png" width={16} alt="" style={{display: 'block', border: 0, height: 'auto'}} />
                                                                                                  </td>
                                                                                                  <td style={{padding: '0 0 3px', verticalAlign: 'middle'}}>
                                                                                                      <p style={{color: '#656567', fontFamily: 'Roboto,Arial,sans-serif', fontSize: '11px', lineHeight: '16px', margin: '0 auto', padding: 0, textAlign: 'left'}}>
                                                                                                          <a href={`tel:${card.phone ? card.phone : '855 569 3700'}`} style={{color: '#656567', textDecoration: 'none'}}>{!card.phone && '855 569 3700'}
                                                                                                              {card.phone}</a>
                                                                                                      </p></td>
                                                                                              </tr>
                                                                                              </tbody></table>
                                                                                      </td>
                                                                                      <td style={{padding: 0, width: '10px'}} />
                                                                                      {card.faxPhone &&
                                                                                      <td style={{padding: 0}}>
                                                                                          <table style={{
                                                                                              borderCollapse: 'collapse',
                                                                                              borderSpacing: 0,
                                                                                              width: 'auto'
                                                                                          }}>
                                                                                              <tbody>
                                                                                              <tr>
                                                                                                  <td style={{
                                                                                                      padding: '0 7px 3px 0',
                                                                                                      verticalAlign: 'middle',
                                                                                                      width: '16px'
                                                                                                  }}>
                                                                                                      <img
                                                                                                          src="https://esig.emortgagecapital.com/full/img/p2.png"
                                                                                                          width={16}
                                                                                                          alt=""
                                                                                                          style={{
                                                                                                              display: 'block',
                                                                                                              border: 0,
                                                                                                              height: 'auto'
                                                                                                          }}/>
                                                                                                  </td>
                                                                                                  <td style={{
                                                                                                      padding: '0 0 3px',
                                                                                                      verticalAlign: 'middle'
                                                                                                  }}>
                                                                                                      <p style={{
                                                                                                          color: '#656567',
                                                                                                          fontFamily: 'Roboto,Arial,sans-serif',
                                                                                                          fontSize: '11px',
                                                                                                          lineHeight: '16px',
                                                                                                          margin: '0 auto',
                                                                                                          padding: 0,
                                                                                                          textAlign: 'left'
                                                                                                      }}>
                                                                                                          <a href={`tel:${card.faxPhone}`}
                                                                                                             style={{
                                                                                                                 color: '#656567',
                                                                                                                 textDecoration: 'none'
                                                                                                             }}>{card.faxPhone}</a>
                                                                                                      </p></td>
                                                                                              </tr>
                                                                                              </tbody>
                                                                                          </table>
                                                                                      </td>
                                                                                      }
                                                                                  </tr>
                                                                                  </tbody></table>
                                                                          </td>
                                                                      </tr>
                                                                      {card.homePhone &&
                                                                      <tr>
                                                                          <td style={{padding: 0}}>
                                                                              <table style={{borderCollapse: 'collapse', borderSpacing: 0, width: '100%', height: '19px'}}>
                                                                                  <tbody><tr>
                                                                                      <td style={{padding: '0 7px 3px 0', verticalAlign: 'middle', width: '16px'}}>
                                                                                          <img src="https://esig.emortgagecapital.com/full/img/p3.png" width={16} alt="" style={{display: 'block', border: 0, height: 'auto'}} />
                                                                                      </td>
                                                                                      <td style={{padding: '0 0 3px', verticalAlign: 'middle'}}>
                                                                                          <p style={{color: '#656567', fontFamily: 'Roboto,Arial,sans-serif', fontSize: '11px', lineHeight: '16px', margin: '0 auto', padding: 0, textAlign: 'left'}}>
                                                                                              <a href="tel:" style={{color: '#656567', textDecoration: 'none'}}>{card.homePhone}</a>
                                                                                          </p></td>
                                                                                  </tr>
                                                                                  </tbody></table>
                                                                          </td>
                                                                      </tr>
                                                                      }
                                                                      <tr>
                                                                          <td style={{padding: 0}}>
                                                                              <table style={{borderCollapse: 'collapse', borderSpacing: 0, width: '100%'}}>
                                                                                  <tbody><tr>
                                                                                      <td style={{padding: '0 7px 3px 0', verticalAlign: 'middle', width: '16px'}}>
                                                                                          <img src="https://esig.emortgagecapital.com/full/img/e.png" width={16} alt="" style={{display: 'block', border: 0, height: 'auto'}} />
                                                                                      </td>
                                                                                      <td style={{padding: '0 0 3px', verticalAlign: 'middle'}}>
                                                                                          <p style={{color: '#656567', fontFamily: 'Roboto,Arial,sans-serif', fontSize: '11px', lineHeight: '16px', margin: '0 auto', padding: 0, textAlign: 'left'}}>
                                                                                              <a href={`mailto:${card.email}`} style={{color: '#656567', textDecoration: 'none'}}>{!card.email && 'youremail@emortgagecapital.com'}
                                                                                                  {card.email}</a>
                                                                                          </p></td>
                                                                                  </tr>
                                                                                  </tbody></table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td style={{padding: 0}}>
                                                                              <table style={{borderCollapse: 'collapse', borderSpacing: 0, width: '100%'}}>
                                                                                  <tbody><tr>
                                                                                      <td style={{padding: '0 7px 3px 0', verticalAlign: 'middle', width: '16px'}}>
                                                                                          <img src="https://esig.emortgagecapital.com/full/img/l.png" width={16} alt="" style={{display: 'block', border: 0, height: 'auto'}} />
                                                                                      </td>
                                                                                      <td style={{padding: '0 0 3px', verticalAlign: 'middle'}}>
                                                                                          <p style={{color: '#656567', fontFamily: 'Roboto,Arial,sans-serif', fontSize: '11px', lineHeight: '16px', margin: '0 auto', padding: 0, textAlign: 'left'}}>
                                                                                              <a href={`https://maps.google.com/?q=${card.address ? card.address : '18071 Fitch Ste 200, Irvine CA 92614' }`} target="_blank" style={{color: '#656567', textDecoration: 'none'}}>{!card.address && '18071 Fitch Ste 200, Irvine CA 92614'}{card.address}</a>
                                                                                          </p></td>
                                                                                  </tr>
                                                                                  </tbody></table>
                                                                          </td>
                                                                      </tr>
                                                                      </tbody></table>
                                                                  <p style={{color: '#656567', fontFamily: 'Roboto,Arial,sans-serif', fontSize: '16px', lineHeight: '18px', margin: '0 auto', padding: 0, textAlign: 'left'}}>
                                                                      <a href={card.apply_now ? card.apply_now : 'https://emchq.floify.com/'} target="_blank" style={{color: '#1f3864', fontWeight: 500, textDecoration: 'underline'}}>Apply Now</a><span style={{color: '#52afe6'}}> | </span><a href={card.document_upload ? card.document_upload : 'https://emchq.floify.com/'} target="_blank" style={{color: '#1f3864', fontWeight: 500, textDecoration: 'underline'}}>Document Upload</a><span style={{color: '#52afe6'}}> | </span><a href={card.reviews? card.reviews : 'https://emortgagecapital.com/reviews/'} target="_blank" style={{color: '#1f3864', fontWeight: 500, textDecoration: 'underline'}}>Reviews</a>
                                                                      {card.schedule_appt && <>
                                                                        <span style={{color: '#52afe6'}}> | </span><br />
                                                                        <a href={card.schedule_appt} target="_blank" style={{color: '#1f3864', fontWeight: 500, textDecoration: 'underline'}}>Schedule Appt</a>
                                                                      </>}
                                                                  </p></td>
                                                          </tr>
                                                          </tbody></table>
                                                  </td>
                                              </tr>
                                              </tbody></table>
                                      </td>
                                  </tr>
                                  </tbody></table>
                          </td>
                      </tr>
                      <tr>
                          <td style={{padding: '10px 0 5px 15px', verticalAlign: 'top'}}>
                              <table style={{borderCollapse: 'collapse', borderSpacing: 0, width: '100%'}}>
                                  <tbody><tr>
                                      <td style={{padding: 0, verticalAlign: 'middle'}}>
                                          <a href="https://emortgagecapital.com/" target="_blank" style={{textDecoration: 'none'}}><img src="https://esig.emortgagecapital.com/full/img/emortgage.png" width={170} alt="" style={{display: 'block', border: 0, height: 'auto'}} /></a>
                                      </td>
                                      <td style={{padding: 0, verticalAlign: 'middle'}}>
                                          <img src="https://esig.emortgagecapital.com/full/img/awards.png" className="float-right" width={320} alt="" style={{display: 'block', float: 'right', textAlign: 'right', border: 0, height: 'auto'}} />
                                      </td>
                                  </tr>
                                  </tbody></table>
                          </td>
                      </tr>
                      </tbody></table>
              </div>

              <div style={{paddingTop:'15px'}}>
                  <textarea ref={textRef} style={{width:'530px'}} rows={15}></textarea>
                  <p className="uk-margin">
                      <button className="uk-button uk-button-default uk-button-large" onClick={onGenerate}>GENERATE</button>
                      <button style={{marginLeft:'20px'}} className="uk-button uk-button-default uk-button-large" onClick={clear}>Clear</button>
                  </p>
              </div>
          </div>
        <ToastContainer />
    </div>
  );
}

export default App;
